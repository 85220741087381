import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "../js/shipping-service";
// import commonService from "../../common/js/common-api-service";
import FilterComp from "../../common/filter-comp.vue";

export default {
  name: "shippingQueue",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),

      actionList: [],
      arrayData: [],
      searchFilter: "",
      favouriteList: [],
      projectList: [],
      businessList: [],
      orderTypeList: [],
      countryList: [],
      ShippingDataTemp: [],
      warehouseLocList: [],
      uniqueProject: [],
      uniqueBusiness: [],
      uniqueOrderType: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      filterPorjectName: "",
      filterBusinessName: "",
      filterOrderName: "",
      filterStatus: "",
      selectedFilterName: "",
      selectedFilterId: 0,
      searchKey: "",
      showAdd: false,
      refresh: true,
      configDialog: false,
      disableBusiness: true,
      disableOrder: true,
      disableCountry: true,
      disableWareLoc: true,
      disableApply: true,
      showConfig: true,
      projIdList: [],
      buKeyList: [],
      wareKeyList: [],
      projectName: [],
      businessName: [],
      finalBusinessList:[],
      orderTypeName: [],
      countryName: [],
      wareLocName: [],
      uniqueStatus: [],
      ShippingData: [],
      filterorderTypeList: [],
      filterBusinessList: [],
      filterProjList: [],
      finalOrderList: [],
      finalCountryList: [],
      finalWarehouseList: [],
      configure: {
        UserID: EncryptUtility.localStorageDecrypt("userID"),
        ddl_pass: 0,
        projkey_list: [],
        bukey_list: [],
        otype_list: [],
        country_list: [],
        warehouse_list: [],
      },
      shippingListHeader: [
        {text: "Project/Business",value: "Project",class: "primary customwhite--text", },
        {text: "Order",value: "OrderNo",class: "primary customwhite--text",},
        {text: "Customer ID",value: "Request_Number",class: "primary customwhite--text",},
        {text: "Type",value: "OrderType",class: "primary customwhite--text"},
        { text: "Ordered Date", value: "OrderDate", class: "primary customwhite--text",},
        { text: "Required Date", value: "Req Date", class: "primary customwhite--text"},
        {text: "New Hire", value: "IsNewHire",  class: "primary customwhite--text"},
        {text: "Carrier",  value: "SLevel",  class: "primary customwhite--text"},
        {text: "Status", value: "Status", class: "primary customwhite--text"},
        {text: "Priority", value: "Priority", class: "primary customwhite--text"},
        {text: "", value: "Ship", class: "primary customwhite--text"},

      ],
      totalRecords: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
    this.getShipPreference();
    // this.timer = setInterval(() => {
    //   this.getProjectData();
    //   this.getShipPreference();

    // }, 60000);
  },
  async mounted() { },
  methods: {
    //Reset Function
    async resetFunction() {
      this.configure = {
        UserID: EncryptUtility.localStorageDecrypt("userID"),
        ddl_pass: 0,
        projkey_list: [],
        bukey_list: [],
        otype_list: [],
        country_list: [],
        warehouse_list: [],
      };
      this.disableBusiness = true;
      this.disableOrder = true;
      this.disableCountry = true;
      this.disableWareLoc = true;
      this.disableApply = true;
      this.wareName = [];
      this.projName = [];
      this.businessName = [];
      this.closeDialog();
      this.showConfig = true;
      this.configDialog = true;
      let submitObj = {
        userid: this.userId,
        pass: 2,
        json: "",
      };
      await shippingService.postShippingQueueFilter("post", submitObj, false);
      this.totalRecords = 0;
      this.ShippingData = [];
      this.configure = {
        UserID: EncryptUtility.localStorageDecrypt("userID"),
        ddl_pass: 0,
        projkey_list: [],
        bukey_list: [],
        otype_list: [],
        country_list: [],
        warehouse_list: [],
      };
      clearInterval(this.timer);
    },
    //Get Project Data
    async getProjectData() {
      let projectObj = {
        UserID: this.userId,
        ddl_pass: 1,
        projkey_list: "[]",
        bukey_list: "[]",
        otype_list: "[]",
        country_list: "[]",
      };
      let projectData = await shippingService.postShippingQueue("post", projectObj);
      this.projectList = projectData;
    },
    async getShipPreference() {
      //Check if Configure Data exists or not
      let configObj = {
        userid: this.userId,
        pass: 1,
        json: "",
      };
      let res = await shippingService.postShippingQueueFilter("post", configObj, false);
      if (res?.proj_name) {
        this.showConfig = false;
        this.projectName = res?.proj_name;
        this.businessName = res?.bu_name;
        this.orderTypeName = res?.order_type;
        this.countryName = res?.country;
        this.wareLocName = res?.ware_comp_name;
        this.configure.projkey_list = res?.proj_obj;
        this.configure.bukey_list = res?.bu_key_obj;
        this.configure.otype_list = res?.order_type;
        if (!this.showConfig) {
          let advSearchObj = {
            userid: this.userId,
            json: "",
          };
          let searchResult = await shippingService.postAdvSearch("post", advSearchObj);
          if (searchResult?.length) {
            this.totalRecords = searchResult?.length;
            this.ShippingData = searchResult;
            this.ShippingData?.forEach((element) => {
              element.OrderDate = Utility.convertESTToLocal(element.OrderDate);
            });
            this.filterArrayData()
            this.uniqueStatus = [...new Set(this.ShippingData.map((item) => item.Status))];
            this.ShippingDataTemp = this.ShippingData;
            if (this.selectedFilterName) { this.filteredValues(this.selectedFilterName, this.selectedFilterId) }
          } else {
            this.totalRecords = 0;
            this.ShippingData = [];
          }
        }
      } else this.showConfig = true;
    },
    filterArrayData() {
      this.uniqueStatus = [...new Map(this.ShippingData.map((item) => [item["Status"], item.Status])).values()];
      this.uniqueProject = [...new Map(this.ShippingData.map((item) => [item["Project"], item.Project])).values()];
      this.uniqueBusiness = [...new Map(this.ShippingData.map((item) => [item["business"], item.business])).values()];
      this.uniqueOrderType = [...new Map(this.ShippingData.map((item) => [item["OrderType"], item.OrderType])).values()];
      this.arrayData = [
        { id: 1, label: "Project", array: this.uniqueProject },
        { id: 2, label: "Business", array: this.uniqueBusiness },
        { id: 3, label: "Status", array: this.uniqueStatus },
        { id: 4, label: "Order Type", array: this.uniqueOrderType },

      ];
    },
    // Close Pop up
    closeDialog() {
      this.configure = {
        UserID: EncryptUtility.localStorageDecrypt("userID"),
        ddl_pass: 0,
        projkey_list: [],
        bukey_list: [],
        otype_list: [],
        country_list: [],
        warehouse_list: [],
      };
      this.configDialog = false;
      this.wareName = [];
      this.projName = [];
      this.businessName = [];
      this.disableBusiness = true;
      this.disableOrder = true;
      this.disableCountry = true;
      this.disableWareLoc = true;
      this.disableApply = true;
    },
    // on Chnage Project
    async onChangeProject(projList) {
      this.projIdList = [];
      projList.forEach((element) => {
        this.projIdList.push(element.proj_key);
      });
      this.disableBusiness = false;
      let businessObj = {
        UserID: this.userId,
        ddl_pass: 2,
        projkey_list: JSON.stringify(this.projIdList),
        bukey_list: "[]",
        otype_list: "[]",
        country_list: "[]",
      };
      let businessData = await shippingService.postShippingQueue("post", businessObj);
      this.businessList = businessData;
      this.businessList.unshift({ bu_key: "All", business: "All" })
      this.businessList.forEach(x => x.disabled = false)
      this.configure.bukey_list = [];
      this.configure.otype_list = [];
      this.configure.country_list = [];
      this.configure.warehouse_list = [];
      this.disableOrder = true;
      this.disableCountry = true;
      this.disableWareLoc = true;
      this.disableApply = true;
    },
    // onChangeBusiness
    async onChangeBusiness(busList) {
      let bu_keyList = busList.map((x) => (x.bu_key));
      this.finalBusinessList = this.commonAllFunction(bu_keyList, this.businessList, "bu_key", "bukey_list")
      let orderTypeObj = {
        UserID: this.userId,
        ddl_pass: 3,
        projkey_list: JSON.stringify(this.projIdList),
        bukey_list: JSON.stringify(this.finalBusinessList),
        otype_list: "[]",
        country_list: "[]",
      };
      let orderTypeData = await shippingService.postShippingQueue("post", orderTypeObj);
      this.orderTypeList = orderTypeData;
      if(this.orderTypeList?.length>0){
      this.disableOrder = false;
      this.orderTypeList.unshift({ order_type: "All" })
      this.orderTypeList.forEach(x => x.disabled = false)}
      else
      {
        this.showErrorToast()
      }
      this.configure.otype_list = [];
      this.configure.country_list = [];
      this.configure.warehouse_list = [];
      this.disableCountry = true;
      this.disableWareLoc = true;
      this.disableApply = true;
    },
    //on CHange Order Type
    async onChangeOrderType() {
      this.finalOrderList = this.commonAllFunction(this.configure.otype_list, this.orderTypeList, "order_type", "otype_list")
      let countryObj = {
        UserID: this.userId,
        ddl_pass: 4,
        projkey_list: JSON.stringify(this.projIdList),
        bukey_list: JSON.stringify(this.finalBusinessList),
        otype_list: JSON.stringify(this.finalOrderList),
        country_list: "[]",
      };
      let countryData = await shippingService.postShippingQueue("post", countryObj);
      this.countryList = countryData;
      if(this.countryList?.length>0){
      this.countryList.unshift({ sh_country: "All" })
      this.countryList.forEach(x => x.disabled = false)
      this.disableCountry = false;
      }

    else{
      this.showErrorToast()
    }
    this.disableWareLoc = true;

    },
    // on Change with country
    async onChangeCountry() {
      this.finalCountryList = this.commonAllFunction(this.configure.country_list, this.countryList, "sh_country", "country_list")
      this.disableWareLoc = false;
      let wareObj = {
        UserID: this.userId,
        ddl_pass: 5,
        projkey_list: JSON.stringify(this.projIdList),
        bukey_list: JSON.stringify(this.finalBusinessList),
        otype_list: JSON.stringify(this.finalOrderList),
        country_list: JSON.stringify(this.finalCountryList),
      };
      let wareData = await shippingService.postShippingQueue("post", wareObj);
      this.warehouseLocList = wareData;
      if(this.warehouseLocList?.length>0){
      this.warehouseLocList.unshift({ comp_id: "All", company: "All" })
      this.warehouseLocList.forEach(x => x.disabled = false)
      this.configure.warehouse_list = [];
      this.disableApply = true;
      }
      else{
        this.showErrorToast()
      }
    },
    async onChangeWareLoc(wareData) {
      let wareList = wareData.map((x) => (x.comp_id));
      this.wareKeyList = [];
      this.disableApply = false;
      this.finalWarehouseList = this.commonAllFunction(wareList, this.warehouseLocList, "comp_id", "warehouse_list")
    },
    async onClickApply() {
      let wareName = [],
        projName = [],
        businessName = [];
        this.selectedFilterName="";
        this.selectedFilterId="";
      if (this.configure.warehouse_list[0] == "All") {
        let wareLoc = this.warehouseLocList.filter((element) => element.comp_id != "All")
        wareName = wareLoc.map(x => x.company)
      }
      else {
        this.configure.warehouse_list.forEach((element) => {
          wareName.push(element.company);
        });
      }
      this.configure.projkey_list.forEach((element) => {
        projName.push(element.project);
      });
      if (this.configure.bukey_list[0] == "All") {
        let buList = this.businessList.filter((element) => element.bu_key != "All")
        businessName= buList.map(x => x.business)
      }
      else {
        this.configure.bukey_list.forEach((element) => {
          businessName.push(element.business);
        });
      }
      let configureJSON = {
        proj_key: this.projIdList,
        proj_name: projName,
        proj_obj: this.configure.projkey_list,
        bu_key: this.finalBusinessList,
        bu_name: businessName,
        bu_key_obj: this.finalBusinessList,
        order_type: this.finalOrderList,
        country: this.finalCountryList,
        ware_comp_key: this.finalWarehouseList,
        ware_comp_name: wareName,
      }
      let submitObj = {
        userid: this.userId,
        pass: 2,
        json: JSON.stringify(configureJSON),
      };
      let res = await shippingService.postShippingQueueFilter("post", submitObj, true);
      if (res?.response) {
        this.closeDialog();
        this.getShipPreference();
      }
      let advSearchObj = {
        userid: this.userId,
        json: "",
      };
      let searchResult = await shippingService.postAdvSearch("post", advSearchObj);
      if (searchResult?.length) {
        this.totalRecords = searchResult.length;
        this.ShippingData = searchResult;
        this.ShippingData.forEach((element) => {
          element.OrderDate = Utility.convertESTToLocal(element.OrderDate);
        });
        this.ShippingDataTemp = this.ShippingData;
      } else {
        this.totalRecords = 0;
        this.ShippingData = [];
      }
    },

     //Common function for All Or Changes
     commonAllFunction(selectedArray, displayArray,key,arrayName) {
      if (selectedArray.includes("All")) {
        selectedArray = ["All"];
        displayArray?.forEach(x => {
          x.disabled = x[key] !== "All";
        });
        this.configure[arrayName]=selectedArray
        let tempReturnArray=displayArray.filter(x=>x[key]!="All")
        let returnArray=tempReturnArray.map(x=>x[key])
        return returnArray
      } else {
        displayArray?.forEach(x => {
          x.disabled = false;
        });
        let returnArray=selectedArray
        return returnArray
      } 
    },
    async onClickSearch() {
      let searchObj = {
        user_key: this.userId,
        search: this.searchKey,
      };
      let searchResult = await shippingService.postSearchShip("post", searchObj, true);
      if (searchResult?.length) {
        this.totalRecords = searchResult.length;
        this.ShippingData = searchResult;
        this.ShippingData.forEach((element) => {
          element.OrderDate = Utility.convertESTToLocal(element.OrderDate);
        });
        this.filterArrayData()
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Result Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.totalRecords = 0;
        this.ShippingData = [];
      }
      if (this.totalRecords == 1 && this.actionSetter("ShippingFulfillment")) {
        this.routerFunction(this.ShippingData[0].so_id);
      }
    },
    // on Click Row
    clickRow(value){
      this.routerFunction(value.so_id);
    },
    // Redirect Function
    routerFunction(id) {
      this.$router.push({
        name: "shippingFulfillment",
        params: {
          id: btoa(id.toString()),
        },
      });
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    async filteredValues(value, type) {
      this.selectedFilterName=value
      this.selectedFilterId=type
      const filters = {
        0: () => this.ShippingDataTemp,
        1: (value) => this.ShippingDataTemp.filter((element) => element.Project == value),
        2: (value) => this.ShippingDataTemp.filter((element) => element.business == value),
        3: (value) => this.ShippingDataTemp.filter((element) => (element.Status = value)),
        4: (value) => this.ShippingDataTemp.filter((element) => element.OrderType == value)


      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.ShippingData = data;
    },
    // on Filter Change

    refreshPage() {
      this.searchKey = "";
      this.filteredValues(this.selectedFilterName, this.selectedFilterId)
    },
    clearFilter() {
      this.selectedFilterName="";
      this.selectedFilterId="";
      this.filterBusinessName = "";
      this.filterPorjectName = "";
      this.filterOrderName = "";
      this.filterStatus = "";
      this.selectedFilterName = "";
      this.selectedFilterId = 0;
      this.getShipPreference()
    },
    showErrorToast(){
      let Alert = {
        type: "error",
        isShow: true,
        message: "No Data Available",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    
    }
  },
  components: {
    breadcrumbComp,
    FilterComp
  },
};
